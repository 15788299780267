<template>
  <div class="cart" v-if="$store.state.scenario.currentStep.layout.show_cart">
    <div class="d-none d-md-block cart__head">
      Summe
    </div>
    <div
      class="cart__body"
      v-if="$store.state.scenario.currentStep.layout.show_cart_body || hasMonthlyRate">
      <div class="cart__border cart__items" v-if="hasItems">
        <CartItem
          :key="item.label"
          v-for="item in items"
          :label="item.label"
          :amount="item.price" />
      </div>
      <div class="cart__border cart__discounts" v-if="hasDiscounts">
        <CartItem
          :key="discount.label"
          v-for="discount in discounts"
          :label="discount.label"
          :amount="discount.price"
          is-discount />
      </div>
    </div>
    <div class="d-none d-md-block cart__footer">
      <div class="cart__total">
        <CartItem
          :key="total.label"
          v-for="total in totals"
          :label="total.label"
          :amount="total.price" />
      </div>
    </div>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import { mapState } from 'vuex';
import CartItem from './CartItem.vue';

export default {
    components: {
        CartItem,
    },
    computed: {
        ...mapState('cart', {
            items: (state) => filter(state.content, (o) => o.price >= 0),
            discounts: (state) => filter(state.content, (o) => o.price < 0),
            totals: (state) => state.totals,
        }),
        hasItems() {
            return this.items.length > 0;
        },
        hasDiscounts() {
            return this.discounts.length > 0;
        },
        hasMonthlyRate() {
            return this.items.some((item) => item.uid === 'ipaMonthlyInstallment');
        },
    },
};
</script>
