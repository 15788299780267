import store from '@/store/';

/**
 * Data adapter.
 * This is the unique translation layer between the backend and this application's data store.
 *
 * It is useful for multiple reasons:
 * - at the moment, the data structure of the different contracts is very inconsistent (different
 * key names, different data points, etc.).
 * - the payload we need to send back to the backend to sign a contract is very different from one
 * contract type to another.
 *
 * In order to keep this application as DRY as possible, we want to:
 * - normalize the data by putting in a base object all common data points (first name, last name,
 * zipcodes, etc.)
 * - prepare the right payload depending on the contract type to be signed.
 *
 * This data adapter consists in these two functions.
 */

/**
 * Normalize contract data (received from the backend).
 */
const normalizeContractData = (data) => {
    const { mode } = store.state.settings;

    // Shared data across all contract types
    let normalizedData = {};

    if (mode === 'customerOffer') {
        // Specific data for customerOffer
        normalizedData = {
            ...normalizedData,
            contract_number: data.number,
            has_secondary_customer: false,
            contract_acceptance_status: {
                primary_customer: data.ordered,
                secondary_customer: false,
            },
            customer: {
                primary: {
                    salutation: data.customer.salutation,
                    title: data.customer.title,
                    first_name: data.customer.first_name,
                    last_name: data.customer.last_name,
                    date_of_birth: data.customer.date_of_birth,
                },
                secondary: {
                    salutation: false,
                    title: false,
                    first_name: false,
                    last_name: false,
                },
                address_billing: {
                    zipcode: data.customer.address_mailing.zipcode,
                    city: data.customer.address_mailing.city,
                    street: data.customer.address_mailing.street,
                    house_number: data.customer.address_mailing.house_number,
                },
                address_installation: {
                    zipcode: data.customer.address_business.zipcode,
                    city: data.customer.address_business.city,
                    street: data.customer.address_business.street,
                    house_number: data.customer.address_business.house_number,
                },
            },
            components: data.components,
            price: {
                with_tax: data.consumer_price_with_tax,
                without_tax: data.consumer_price_without_tax,
                tax: data.consumer_price_tax,
            },
            prepayment: {
                prepayment_amount: data.prepayment_amount,
                prepayment_discount_amount: data.prepayment_discount_amount,
                prepayment_discount_percent: data.prepayment_discount_percent,
                prepayment_final_payment_amount: data.prepayment_final_payment_amount,
                prepayment_final_payment_percent: data.prepayment_final_payment_percent,
                prepayment_percent: data.prepayment_percent,
                prepayment_discount_validity_in_days: data.prepayment_discount_validity_in_days,
            },
            ipa: {
                eligible_for_IPA: data.offer_eligible_for_ipa,
                primary_customer_eligible_by_age: data.primary_customer_eligible_for_ipa,
                monthly_rate_before_subsidy: data.ipa_monthly_rate_before_subsidy,
                monthly_rate_after_subsidy: data.ipa_monthly_rate_after_subsidy,
                yearly_effective_interest_rate: data.ipa_effective_interest_rate,
                years_of_payment: data.ipa_duration_in_years,
                number_of_payments: data.ipa_number_of_payments,
                total_amount_including_subsidy: data.ipa_total_amount_including_subsidy,
                total_amount_excluding_subsidy: data.ipa_total_amount_excluding_subsidy,
                subsidy_percent_additional_housing_units:
                    data.subsidy_percent_additional_housing_units,
                subsidy_percent_first_housing_unit: data.subsidy_percent_first_housing_unit,
                contract_pdf: data.ipa_contract_pdf,
            },
        };
    }

    if (mode === 'maintenanceOffer') {
        normalizedData = {
            ...normalizedData,
            contract_acceptance_status: {
                primary_customer: false,
                secondary_customer: false,
            },
            customer: {
                primary: {
                    salutation: data.customer.salutation,
                    title: data.customer.title,
                    first_name: data.customer.first_name,
                    last_name: data.customer.last_name,
                    phone: data.customer.phone,
                    email: data.customer.email,
                },
                secondary: {
                    salutation: false,
                    title: false,
                    first_name: false,
                    last_name: false,
                },
                address_billing: {
                    zipcode: data.customer.address_mailing.zipcode,
                    city: data.customer.address_mailing.city,
                    street: data.customer.address_mailing.street,
                    house_number: data.customer.address_mailing.house_number,
                },
                address_installation: {
                    zipcode: data.customer.address_business.zipcode,
                    city: data.customer.address_business.city,
                    street: data.customer.address_business.street,
                    house_number: data.customer.address_business.house_number,
                },
            },
            hardware: {
                energy_source: data.parameters.energy_source,
                energy_source_identifier: data.parameters.energy_source_identifier,
                burner_manufacturer: data.parameters.burner_manufacturer,
                burner_type: data.parameters.burner_type,
                boiler_manufacturer: data.parameters.boiler_manufacturer,
                boiler_power: data.parameters.boiler_power,
                water_tank_manufacturer: data.parameters.water_tank_manufacturer,
                water_tank_type: data.parameters.water_tank_type,
                solar_collector_manufacturer: data.parameters.solar_collector_manufacturer,
                solar_collector_type: data.parameters.solar_collector_type,
            },
            packages: data.maintenance_packages,
            solar_package: {
                price: data.solar_package.price,
                selected: data.solar_package.selected,
            },
            water_tank_package: {
                price: data.water_tank_package.price,
                selected: data.water_tank_package.selected,
            },
            discounts: [
                {
                    affectedYear: 1,
                    amount: data.personal_discount_first_year || 0,
                },
                {
                    affectedYear: 2,
                    amount: data.personal_discount_second_year || 0,
                },
            ],
        };
    }

    if (mode === 'contracting') {
        // Specific data for contracting
        normalizedData = {
            ...normalizedData,
            duration_in_months: data.duration_in_months,
            contract_partner: data.contract_partner,
            contract_number: data.contract_number,
            customer_offer_number: data.customer_offer_number,
            monthly_gross: data.monthly_gross,
            monthly_net: data.monthly_net,
            monthly_tax: data.monthly_tax,
            down_payment: data.down_payment,
            has_secondary_customer: data.has_secondary_customer,
            contract_acceptance_status: {
                primary_customer: data.contract_acceptance_status.primary_customer,
                secondary_customer: data.contract_acceptance_status.secondary_customer,
            },
            customer: {
                primary: {
                    salutation: data.customer_information.primary_customer.salutation,
                    title: data.customer_information.primary_customer.title,
                    first_name: data.customer_information.primary_customer.first_name,
                    last_name: data.customer_information.primary_customer.last_name,
                    date_of_birth: data.customer_information.primary_customer.date_of_birth,
                    hashed_id: data.customer_information.primary_customer.hashed_id,
                },
                secondary: {
                    salutation: data.customer_information.secondary_customer.salutation,
                    title: data.customer_information.secondary_customer.title,
                    first_name: data.customer_information.secondary_customer.first_name,
                    last_name: data.customer_information.secondary_customer.last_name,
                    date_of_birth: data.customer_information.secondary_customer.date_of_birth,
                    hashed_id: data.customer_information.secondary_customer.hashed_id,
                },
                address_billing: {
                    zipcode: data.customer_information.address_mailing.zipcode,
                    city: data.customer_information.address_mailing.city,
                    street: data.customer_information.address_mailing.street,
                    house_number: data.customer_information.address_mailing.house_number,
                },
                address_installation: {
                    zipcode: data.customer_information.address_business.zipcode,
                    city: data.customer_information.address_business.city,
                    street: data.customer_information.address_business.street,
                    house_number: data.customer_information.address_business.house_number,
                },
            },
            contract_version: {
                direct_debit_authorization:
                    data.heating_contract_version_information.direct_debit_authorization,
                application_submission:
                    data.heating_contract_version_information.application_submission,
                terms_and_conditions:
                    data.heating_contract_version_information.terms_and_conditions,
                cancellation_policy:
                    data.heating_contract_version_information.cancellation_policy,
            },
        };
    }

    return normalizedData;
};

/**
 * Prepare signature payload (to sign the contract).
 */
const prepareSignaturePayload = () => {
    const { mode } = store.state.settings;
    const { collectedData } = store.state;
    const { maintenanceOffer } = store.state.status;
    // Shared data across all apps
    let preparedSignaturePayload = {};

    if (mode === 'customerOffer') {
        const { paymentMethod } = collectedData.customerOffer;
        if (paymentMethod === 'prepayment') {
            preparedSignaturePayload = {
                ...preparedSignaturePayload,
                bank_name: collectedData.customerOffer.bankAccountBankName,
                iban: collectedData.customerOffer.bankAccountIban,
                account_owner: collectedData.customerOffer.bankAccountOwner,
                direct_debit_authorization_text: collectedData.customerOffer
                    .bankAccountDirectDebitAuthorisationText,
                payment_method: paymentMethod,
            };
        } else if (paymentMethod === 'financing') {
            preparedSignaturePayload = {
                ...preparedSignaturePayload,
                payment_method: paymentMethod,
            };
        } else if (paymentMethod === 'ipa') {
            preparedSignaturePayload = {
                ...preparedSignaturePayload,
                bank_name: collectedData.customerOffer.bankAccountBankName,
                iban: collectedData.customerOffer.bankAccountIban,
                account_owner: collectedData.customerOffer.bankAccountOwner,
                direct_debit_authorization_text: collectedData.customerOffer
                    .bankAccountDirectDebitAuthorisationText,
                payment_method: paymentMethod,
            };
        }
    }

    if (mode === 'contracting') {
        preparedSignaturePayload = {
            ...preparedSignaturePayload,
            bank_name: collectedData.contracting.bankAccountBankName,
            iban: collectedData.contracting.bankAccountIban,
            account_owner: collectedData.contracting.bankAccountOwner,
            direct_debit_authorization_text: collectedData.contracting
                .bankAccountDirectDebitAuthorisationText,
        };
    }

    if (mode === 'maintenanceOffer') {
        preparedSignaturePayload = {
            ...preparedSignaturePayload,
            ...(!maintenanceOffer.isPackagePreSelectedInBackend
                && { plan: collectedData.maintenanceOffer.package }
            ),
            is_ordered: true,
            solar: collectedData.maintenanceOffer.addons.solar_package,
            water_tank: collectedData.maintenanceOffer.addons.water_tank_package,
        };
    }

    return preparedSignaturePayload;
};

export {
    normalizeContractData,
    prepareSignaturePayload,
};
