import Vue from 'vue';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import logger from './utils/logging';

/**
 * Convert monetary amounts into german form.
 */
// eslint-disable-next-line import/prefer-default-export
export const priceFilter = (value) => {
    // To prevent 0 being treated as boolean
    if (!value && value !== 0) {
        return '-';
    }

    if (Number.isNaN(parseFloat(value))) {
        logger(
            new Error(
                `priceFilter: ${value} is not a valid price value.`,
            ),
        );
        return null;
    }

    return new Intl.NumberFormat(
        'de-DE',
        {
            style: 'currency',
            currency: 'EUR',
        },
    ).format(value);
};

/**
 * Convert date into german form.
 */
// eslint-disable-next-line import/prefer-default-export
export const dateFormatFilter = (value) => {
    try {
        return format(parseISO(value), 'dd.MM.yyyy');
    } catch (error) {
        logger(
            new Error(
                `dateFormatFilter: ${value} is not a valid date value.`,
            ),
        );
        return null;
    }
};

/**
 * Convert percent.
 */
// eslint-disable-next-line import/prefer-default-export
export const percentFilter = (value, options = {}) => {
    // To prevent 0 being treated as boolean
    if (!value && value !== 0) {
        return '-';
    }

    if (Number.isNaN(parseFloat(value))) {
        logger(
            new Error(
                `percentFilter: ${value} is not a valid percent value.`,
            ),
        );
        return null;
    }

    const formatter = new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'percent',
        ...options,
    });

    return formatter.format(value);
};
// eslint-disable-next-line import/prefer-default-export
export const yearsFilter = (value) => {
    // To prevent 0 being treated as boolean
    if (!value && value !== 0) {
        return '-';
    }

    if (Number.isNaN(parseFloat(value))) {
        logger(
            new Error(
                `yearsFilter: ${value} is not a valid year value.`,
            ),
        );
        return null;
    }

    return `${value} Jahre`;
};

Vue.filter('price', priceFilter);
Vue.filter('date', dateFormatFilter);
Vue.filter('percent', percentFilter);
Vue.filter('years', yearsFilter);
